function HotelPageProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/profile/hotel";
    this.currentCurrency;
    var me = this;
    this.showResult = function () {
        dataActive.hooks.register('afterAjaxError', () => dataActive.pageRoute('hotelResult', { status: true, sessionId: getStorage("hotelSearchDetails").sessionId }));
        ajax({
            url: me.showUrl,
            method: "get",
            data: {
                referenceId: dataActive.location.query.referenceId
            },
            success: function (data) {
                // lug.info("hotel page found");
                me.mapContent(data.data);
                setStorage("hotelDetail", data);
            },
        });
    }
    this.mapContent = function (data) {
        $("title").append(":" + data.hotelProfile.title);
        handler.domNode.find(".loader-area").hide();
        handler.domNode.find(".hotel-page-profile").show();
        handler.domNode.find(".hotel-page-profile").append(view('hotel/hotel-profile-content'));
        var BookingSummaryDesk = handler.domNode.find(".booking-summary-desk");
        var hotelDetaileTop = handler.domNode.find(".hotel-profile-top");
        hotelDetaileTop.append(view("hotel/hotel-profile-banner", { data: data }));
        var hotelDetaileBottom = handler.domNode.find(".hotel-profile-bottom");
        hotelDetaileBottom.append(view("hotel/hotel-profile-details", { data: data }));
        $('.fotorama').fotorama();
        cancelationPolicies = function (data) {
            return `<table class="policiestabel"><thead><tr><td>${trans('From')}</td><td>${trans('To')}</td><td>${trans('Penalty')}</td></tr></thead><tbody>
               ${data.policies.map(function (item) { return '<tr><td>' + DateFormat(item.fromDate, 'YMD') + '</td><td>' + DateFormat(item.toDate, 'YMD') + '</td><td>' + item.cancellationCharge + (item.currency == null ? "%" : item.currency.abb) + '</td></tr>'; }).join("")}
            </tbody></table>`;
        }
        var pageTitle = handler.domNode.find(".page-title");
        var pageTitleText = handler.domNode.find(".profile-page-title").text();
        var newTitle = eval("`" + pageTitleText + "`");
        pageTitle.append(newTitle);
        var numberOfRoom = data.queryParams.rooms_no;
        var numberOfAdults = 0;
        var roomsObject = {};
        data.queryParams.rooms_detail.forEach((item, index) => {
            numberOfAdults += Number(item.adults);
        });
        $.map(data.serviceInfo.rooms, function (roomItem, roomIndex) {
            roomsObject[roomItem['index']] = roomItem;
        });
        me.sugistedCombinations(numberOfRoom, data, roomsObject);
        BookingSummaryDesk.append(view("hotel/hotel-profile-summary", { data: data, numberOfAdults: numberOfAdults }));
        handler.domNode.find(".hotel-book-now").click(me.booking);
        createSticky();
    }

    this.applyCombinationFilter = function () {
        handler.domNode.find('.combination-item').each((index, item) => {
            let $item = $(item);
            let show = true;
            handler.domNode.find('.combinations-filter-name').each((indexOfElement, element) => {
                let $element = $(element);
                if ($element.val() == '') {
                    show = true && show;
                    return;
                }
                show = $item.data('room' + indexOfElement) == $element.val() && show;
            });
            handler.domNode.find('.combinations-filter-meal').each((indexOfElement, element) => {
                let $element = $(element);
                if ($element.val() == '') {
                    show = true && show;
                    return;
                }
                show = ($item.data('inclusion' + indexOfElement)).includes($element.val()) && show;
            });
            if (show) {
                $item.show();
            } else {
                $item.hide();
            }
            return;
        });
    }

    this.filterCombinations = function (data, roomsObject, numberOfRoom) {
        let inclusions = data.serviceInfo.roomsFilters.inclusions;
        data.serviceInfo.roomsFilters.name.forEach((item, index) => {
            handler.domNode.find('.combination-filter').append(view('hotel/combination-filter-section', { numberOfRoom: numberOfRoom, indexOfRoom: index, items: item, inclusions: inclusions }));
        });


        handler.domNode.find('.combination-item').each((index, item) => {
            var $item = $(item);
            if (!$.isEmptyObject($item.data('combination'))) {
                var combination = $item.data('combination').split(',');
                combination.forEach((item, index) => {
                    $item.data(('room' + index), roomsObject[item].name);
                    $item.data(('inclusion' + index), roomsObject[item].inclusion);
                });
            }
        });
        handler.domNode.find('.combinations-filter').change(me.applyCombinationFilter);
    }

    this.sugistedCombinations = function (numberOfRoom, data, roomsObject) {
        var roomsSection = handler.domNode.find(".rooms-section");
        if (numberOfRoom == 1) {
            roomsSection.append(view('hotel/oneroom/rooms-section'));
            var roomItems = handler.domNode.find(".sugisted-rooms");
            for (var index in data.combinations) {
                if (index > 100) {
                    roomsSection.append(`<div class="_mt-10">${trans('more_rooms_available')}</div>`);
                    break;
                }
                var item = data.combinations[index];
                roomItems.append(view('hotel/oneroom/room-details', { data: data, room: roomsObject[item.roomIndexes], combinationItem: item }));
            }
        }
        else {
            roomsSection.append(view('hotel/multiroom/combinations-section'));
            var roomItems = handler.domNode.find(".combination-items");
            for (var combinationIndex in data.combinations) {
                if (combinationIndex > 100) {
                    roomsSection.append(`<div class="_mt-10">${trans('more_rooms_available')}</div>`);
                    break;
                }
                var combinationItem = data.combinations[combinationIndex];
                var sugistedRooms = $('<div></div>');
                combinationItem.roomIndexes.forEach((item, index) => {
                    sugistedRooms.first().append(view('hotel/multiroom/sugisted-rooms', { roomsObject: roomsObject, item: item, data: data, roomIndex: index }));
                });
                roomItems.append(view('hotel/multiroom/combination-item', { sugistedRooms: sugistedRooms[0].innerHTML, data: data, combinationItem: combinationItem, combinationIndex: combinationIndex }));
            }
            handler.domNode.find('.rooms-details-link').click(function () {
                var $this = $(this);
                var combination = $this.data('combination').split(',');
                var detailsTabs = $this.next().find('.rooms-details-tabs');
                if (detailsTabs.children().length == 0) {
                    detailsTabs.append(view('hotel/multiroom/rooms-details-tabs'));
                    combination.forEach((item, index) => {
                        detailsTabs.find('.nav-tabs').append(`<li class= "${(index == 0) ? 'active' : ''}" role = "presentation" > <a role="tab" data-toggle="tab" href="#tab-item-${$this.data('index')}-${index}" aria-controls="tab-item--${index}">${trans('Room')}${index + 1}</a></li > `);
                        detailsTabs.find('.tab-content').append(`<div class= "tab-pane ${(index == 0) ? 'active' : ''}" role = "tabpanel" id = "tab-item-${$this.data('index')}-${index}" > ${view('hotel/multiroom/rooms-details-content', { room: roomsObject[item], data: data })}</div > `);
                    });
                }
            });
        }
        me.filterCombinations(data, roomsObject, numberOfRoom);
    }

    this.booking = function (event) {
        dataActive.hooks.clear('afterAjaxError');
        var $bookNowButton = $(event.target);
        $bookNowButton.attr('disabled', true);
        $bookNowButton.html('<i class="fa fa-circle-o-notch fa-spin buttonload"></i>');
        var loginInformation = getStorage("loginInformation");
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        ajax({
            url: dataActive.location.marketplace + "/v1/book/hotel/create",
            method: "post",
            headers: headers || {},
            data: {
                referenceId: $bookNowButton.data("reference"),
                combination: JSON.parse("[" + $bookNowButton.data("combination") + "]"),
            },
            success: function (data) {
                // setStorage("numberNights", data.booking.service_info.queryParams.nights_no);
                dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "hotel" });
            },
            error: function (data, validation) {
                dataActive.defaultAjaxError(data, validation);
                $bookNowButton.attr('disabled', false);
                $bookNowButton.html(trans('Book_Now'));
            },
        });
    }

    function createSticky () {
        if ($(window).width() > 992) {
            handler.domNode.find('.sticky-col').stick_in_parent({
                parent: '#sticky-parent',
            });
        }
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    this.init = function () {
        this.showResult();
        dataActive.hooks.register('changeCurrency', function (carrency) {
            if (currencyChangeAlert()) {
                return carrency;
            }
        });
        dataActive.hooks.register('changeLanguage', function (language) {
            if (languageChangeAlert()) {
                return language;
            }
        });
    }
    this.init();

}
